@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inria Sans', sans-serif;
}

body {
  margin: 0;
}

.page-container {
  background-color: #e7ddd2;
}
