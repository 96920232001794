.brown-text {
  color: #836552;
}

.brown-background {
  background-color: #836552;
}

.fa-dot {
  padding: 0 0.5rem;
}

.fade-modal {
  background-color: rgba(0, 0, 0, 0.5);
}
